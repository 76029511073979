<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div class="h1">{{ member.name }} (#{{ member.id }})</div>
      <div class="actions d-flex">
        <router-link to="/" class="mr-3" v-if="$route.name != 'Home'"><button class="btn btn-secondary h-100 w-100">Back</button></router-link>
        <button class="btn btn-danger" @click="logout">Logout</button>
      </div>
    </div>
    <router-view @refresh="refresh"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      member: JSON.parse(localStorage.getItem("member")),
      messages: JSON.parse(localStorage.getItem("messages")),
    };
  },
  methods: {
    refresh() {
      this.member = JSON.parse(localStorage.getItem("member"));
      this.messages = JSON.parse(localStorage.getItem("messages"));
    },
  },
};
</script>
