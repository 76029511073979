import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.$cookies.config('1d')

Vue.prototype.$http = Axios.create({
	baseURL: 'https://admin.fitnesspoint.rw/api',
	headers: {
		'Authorization': "Bearer " + Vue.$cookies.get("fp-token")
	}
});

Vue.prototype.$http.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response && error.response.status === 401) {
		Vue.$cookies.remove("fp-token");
		window.location.reload();
	}

	return Promise.reject(error);
});

Vue.mixin({
	methods: {
		async logout() {
			await this.$http.post('/logout');
			this.$cookies.remove("fp-token");
			localStorage.removeItem('member');
			localStorage.removeItem('messages');
			this.$router.go();
		},
	}
})

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
