import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../views/Base.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Base',
	component: Base,
	children: [
		{
			path: '',
			name: 'Home',
			component: () => import('../views/Home.vue')
		},
		{
			path: 'invoices',
			name: 'Invoices',
			component: () => import('../views/Invoices.vue')
		},
		{
			path: 'invoices/:id',
			name: 'Invoice',
			component: () => import('../views/Invoice.vue')
		},
		{
			path: 'profile',
			name: 'Profile',
			component: () => import('../views/Profile.vue')
		},
		{
			path: 'subscription',
			name: 'Subscription',
			component: () => import('../views/Subscription.vue')
		},
	]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Forgot.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ['/login', '/register', '/forgot', '/reset'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = Vue.$cookies.get("fp-token")

	if (authRequired && !loggedIn) {
	  return next('/login');
	}

	next();
  })

export default router
